<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="#CFD8DC"
    flat
    height="75"
    class="my-app-bar-style"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <h4
      v-if="isTrialEndsAtNotified"
      class="trial-expire-alert"
    >
      <v-icon class="my-icon-style">
        mdi-alert
      </v-icon>
      {{ trialEndsAtNotification.event_name }}
      <v-btn
        small
        class="my-btn-style"
        @click="subscribe()"
      >
        Subscribe
      </v-btn>
    </h4>
    <h4
      v-if="isPlanExpired"
      class="trial-expire-alert"
    >
      <v-icon class="my-icon-style">
        mdi-alert
      </v-icon>
      Your plan has expired
      <v-btn
        small
        class="my-btn-style"
        @click="subscribe()"
      >
        Subscribe
      </v-btn>
    </h4>

    <v-spacer />

    <div class="mx-3" />
    <v-tooltip
      v-if="isUserAdmin"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          v-bind="attrs"
          text
          color="#37474F"
          to="/settings"
          :disabled="isPlanExpired"
          v-on="on"
        >
          <v-icon size="25">
            mdi-cog-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Settings</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          v-bind="attrs"
          text
          color="#37474F"
          to="/pages/dashboard"
          :disabled="isPlanExpired"
          v-on="on"
        >
          <v-icon size="25">
            mdi-view-dashboard
          </v-icon>
        </v-btn>
      </template>
      <span>Dashboard</span>
    </v-tooltip>

    <v-menu
      bottom
      right
      offset-y
      origin="top left"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on: onMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onToolTip }">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              color="#37474F"
              v-bind="attrs"
              v-on="{...onMenu, ...onToolTip }"
              @click="clearUnRead()"
            >
              <v-badge
                color="red"
                overlap
                bordered
              >
                <template v-slot:badge>
                  <span>{{ unReadCount }}</span>
                </template>

                <v-icon size="25">
                  mdi-bell
                </v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Notifications</span>
        </v-tooltip>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n.event_name" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <div class="mx-3" />
    <v-menu
      bottom
      rounded
      left
    >
      <template v-slot:activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onToolTip }">
            <v-btn
              icon
              x-small
              v-on="{...onMenu, ...onToolTip}"
              @click="userProfilePage()"
            >
              <v-avatar
                color="#37474F"
                size="33"
                class="my-avatar-style"
              >
                <span class="white--text text-h5">{{ firstName }}{{ lastName }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <span>Profile</span>
        </v-tooltip>
      </template>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib';

  // Utilities
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import global from 'src/mixins/global';

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-isLogged12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default);
              },
            },
          });
        },
      },
    },
    mixins: [global],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      firstName: '',
      lastName: '',
      isUserAdmin: false,
    }),

    computed: {
      ...mapState(['drawer']),
      ...mapGetters({
        isLogged: 'isLogged',
        notifications: 'notifications/getMessages',
        readStatus: 'notifications/getUserRead',
        unReadCount: 'notifications/getUnReadCount',
      }),
      userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
      trialEndsAtNotification () {
        return this.$store.getters['payments/getTrialEndsAtNotification'];
      },
      isTrialEndsAtNotified () {
        return Object.keys(this.trialEndsAtNotification).length > 0;
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      userProfile (val) {
       if (Object.keys(val).length > 0) {
        if (val.role.name === 'userAdmin') {
          this.isUserAdmin = true;
        } else {
          this.isUserAdmin = false;
        }
       }
      },
    },
    async mounted () {
      await this.$store.dispatch('userprofile/fetchUserProfile');
      await this.$store.dispatch('userprofile/fetchOrganizationDetails');
      this.firstName = this.userProfile.first_name[0];
      this.lastName = this.userProfile.last_name[0];
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      userProfilePage () {
        this.$router.push({ name: 'User Profile' }).catch(() => {});
      },
      clearUnRead () {
        this.$store.dispatch('notifications/updateUserRead', true);
        this.$store.dispatch('notifications/updateUnReadCount', 0);
      },
      subscribe () {
        this.$router.push({ name: 'Product Payment Plans' }).catch(() => {});
      },
    },
  };
</script>
<style scoped>
.my-app-bar-title-style {
  font-size: 16px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.my-avatar-style {
  margin-bottom: 8px;
}
.my-btn-style {
  margin-left: 5px;
  background-color: #263238 !important;
  margin-bottom: 0px !important;
}
.trial-expire-alert {
  color: #C62828;
  font-size: 18px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
}
.my-icon-style {
  font-size: 35px;
  color: #C62828;
  margin-left:20px;
}
@media (max-width: 600px) {
.my-icon-style {
  font-size: 20px;
  margin-left:0px;
}
}
@media (max-width: 900px) {
.my-app-bar-style {
  overflow-x:auto;
  white-space: nowrap;
  overflow-y: hidden;
}
}
</style>
